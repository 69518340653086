//
// Tooltips
// --------------------------------------------------


// Base class
.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  visibility: visible;
  // Reset font and text propertes given new insertion method
  font-family: $font-family-base;
  font-size: $font-size-small;
  font-weight: normal;
  line-height: 1.4;
  @include opacity(0);

  &.in     { @include opacity($tooltip-opacity); }
  &.top    { margin-top:  -3px; padding: $tooltip-arrow-width 0; }
  &.right  { margin-left:  3px; padding: 0 $tooltip-arrow-width; }
  &.bottom { margin-top:   3px; padding: $tooltip-arrow-width 0; }
  &.left   { margin-left: -3px; padding: 0 $tooltip-arrow-width; }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: 3px 8px;
  color: $tooltip-color;
  text-align: center;
  text-decoration: none;
  background-color: $tooltip-bg;
  border-radius: $border-radius-base;
}

// Arrows
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip {
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.top-left .tooltip-arrow {
    bottom: 0;
    right: $tooltip-arrow-width;
    margin-bottom: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.top-right .tooltip-arrow {
    bottom: 0;
    left: $tooltip-arrow-width;
    margin-bottom: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
    border-right-color: $tooltip-arrow-color;
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-left-color: $tooltip-arrow-color;
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
  &.bottom-left .tooltip-arrow {
    top: 0;
    right: $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
  &.bottom-right .tooltip-arrow {
    top: 0;
    left: $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
}


.custom_tooltip {

  .tooltip {
    position: absolute;
    z-index: $zindex-tooltip;
    display: block;
    visibility: visible;
    // Reset font and text propertes given new insertion method
    font-family: $font-family-base;
    font-size: $font-size-small;
    font-weight: normal;
    line-height: 1.4;
    @include opacity(0);

    &.in     { opacity:1; }
    &.top    { margin-top:  -3px; padding: $tooltip-arrow-width 0; }
    &.right  { margin-left:  3px; padding: 0 $tooltip-arrow-width; }
    &.bottom { margin-top:   8px; padding: $tooltip-arrow-width 0; }
    &.left   { margin-left: -3px; padding: 0 $tooltip-arrow-width; }
  }

  // Wrapper for the tooltip content
  .tooltip-inner {
    color: $bk;
    max-width: 300px;
    text-align: left;
    text-decoration: none;
    background-color: #ffffff;
    font-size: 16px;
    padding: 20px 15px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
  }

  // Arrows
  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  // Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
  .tooltip {
    &.top .tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -$tooltip-arrow-width;
      border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
      border-top-color: $tooltip-arrow-color;
    }
    &.top-left .tooltip-arrow {
      bottom: 0;
      right: $tooltip-arrow-width;
      margin-bottom: -$tooltip-arrow-width;
      border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
      border-top-color: $tooltip-arrow-color;
    }
    &.top-right .tooltip-arrow {
      bottom: 0;
      left: $tooltip-arrow-width;
      margin-bottom: -$tooltip-arrow-width;
      border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
      border-top-color: $tooltip-arrow-color;
    }
    &.right .tooltip-arrow {
      top: 50%;
      left: 0;
      margin-top: -$tooltip-arrow-width;
      border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
      border-right-color: $tooltip-arrow-color;
    }
    &.left .tooltip-arrow {
      top: 50%;
      right: 0;
      margin-top: -$tooltip-arrow-width;
      border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
      border-left-color: $tooltip-arrow-color;
    }
    &.bottom .tooltip-arrow {
      top: -8px;
      left: 50%;
      margin-left: -13px;
      border-width: 0 10px 13px;
      border-bottom-color: #ffffff;
    }
    &.bottom-left .tooltip-arrow {
      top: 0;
      right: $tooltip-arrow-width;
      margin-top: -$tooltip-arrow-width;
      border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
      border-bottom-color: $tooltip-arrow-color;
    }
    &.bottom-right .tooltip-arrow {
      top: 0;
      left: $tooltip-arrow-width;
      margin-top: -$tooltip-arrow-width;
      border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
      border-bottom-color: $tooltip-arrow-color;
    }
  }

}
