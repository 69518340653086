.tabs_product_module {
	border: 1px solid $gr;
	border-bottom: 0;

	.tab-content, .row {
		.inner_block {
			&:nth-child(6n+6):not(.p0) {
				border-right: 0;
			}
		}
	}
}

.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.cheap_invoice {
	margin-top: 10px;
	a {
		font-family: $hf;
		padding: 0;
		background:none;
		border:0;
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		text-transform: uppercase;
		vertical-align: middle;
		text-decoration: none;
		svg {
			fill: $gr3;
			margin-right: 5px;
			float: left;
			margin-top: 0px;
		}
		&:hover {
			color: $rd;
			svg {
			fill: $rd;
			}
		}
	}
}

.product-block {
	position: relative;
	.description {
		display: none;
	}
	.cart {
		height: 30px;
	}
	.product-meta {
		line-height: 25px;
		padding: 0 10px 20px;
	}
	.bottom {
		display: block;
		button {
			padding: 0;
			border: 0;
		}
		a {
			padding: 0;
			border: 0;
		}
		.add {
			padding: 0;
			border: 0;
			color: $rd2;
			font-weight: bold;
font-size: 16px;
line-height: 24px;
			span {
				float: left;
				padding-right: 4px;
				path {
						fill: $rd2;
					}
				.title {margin-top:2px;}
			}
			&:hover {
				color: $rd;	
				path {
						fill: $rd;
					}
			}
		}
		.compare {
			display: inline-block;
			button {
				path {
						fill: #999;
					}
				&:hover {
					path {
						fill: $dg;
					}
				}
			}
		}
		.quickview {
			display: inline-block;
			padding-right: 8px;
			a {
				path {
						fill: #999;
					}
				&:hover {
					path {
						fill: $dg;
					}
				}
			}
		}
	}
	.name {
		margin: 10px 0;
		font-size: 14px;
		line-height: 18px;
		a {
			color: $black;
			&:hover {
				color: $rd2;
				text-decoration: none;
			}
		}
	}
	.price {
		font-size: 18px;
		line-height: 24px;
		font-family: $hf;
		font-weight: bold;
		float: none;
		display: block;
		overflow: hidden;
		.price-new {
			float: left;
		}
		.price-old {
			float: left;
			font-size: 14px;
			line-height: 24px;
			color: #999999;
			font-weight: normal;
			font-family: $f;
			padding: 0 0px 0px 10px;
		}
	}
	.image {

		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
		img {
			margin: 16px 0 0 0;
		}
	}
	.top {
		position: relative;
		padding-bottom: 25px;
	}
	.rating {
		color: #25B92F;
		height: 25px; 

	}
}

.cart {
	position: relative;
	@include transition(all 0.4s ease 0s);
	/*     z-index: 99; */
	.icon-cart {
		background: url(#{$image-theme-path-base}icon-shopping.png) no-repeat scroll center center;
		display: block;
		@include size(16px, 17px);
	}
}

.over {
	display: block;
	 -moz-box-sizing: border-box; /* Для Firefox */  
    box-sizing: border-box; /* Ширина блока с полями */
    overflow:hidden;
    padding: 0 15px;
    margin: 0 -15px;
}

.more_products, .product-set-btn {
	border: 1px solid $gr;
	font-weight: bold;
	font-size: 24px;
	line-height: 24px;
	padding: 32px 0;
	display: block;
	cursor: pointer;
	text-transform: uppercase;
	font-family: $hf;
	color: #999;
	margin: -1px -15px 0 -15px;
	text-decoration: none;
	&:hover {
		color: $rd2;
		text-decoration: none;
	}
}

.new_label {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 99;
	@include size(94px, 94px);
	.product-label-new {
		color: $lb;
		font-size: 16px;
		font-weight: bold;
		display: inline-block;
		top: 25px;
		position: absolute;
		left: 0px;
		text-transform: uppercase;
	}
}

.scroll-button {
	margin-top: 20px;
	float: right;
}

#sidebar-left {
	border-right: 1px solid $gr;
}
.inner_block {
	border: 0;
	border-right: 1px solid $gr;
	border-bottom: 1px solid $gr;
}

.tabs_product_module {
	.inner_block {
	
	&.p0 {
		padding: 0 0px;
		border-right: 0;
	}
	&:nth-child(2) {
		border-left: 0;
	}
}
}

.product_labels {
	z-index: 100;
	left: 9px;
	top:24px;
	position: absolute;
	text-align: left;

	.product-label {
		&.markdown-product {
			color: $wh;
			background: $rd2;
			padding: 4px;
			line-height: 14px;
		}
	}
}

.product-label {
	margin-bottom: 5px;
	display: inline-block;
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

.price-old {
	text-decoration: line-through;
	color: #666;
}

.func_list {
	display: flex;
	ol {
		font-size: 18px;
	}
	img {
		max-height: 400px;
	}
}

.storage_address {
	font-family: $hf;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
}

#sbscroll {
	max-height: 400px;
	flex: 0 1 500px;
}

.param_list {
	.double, .single {
		background: url(#{$image-theme-path}i/line.png) repeat-x left 18px;
		margin: 0 0 15px 0;
		overflow: hidden;
		& > span {
			background: $wh;
		}
		.param_k {
			color: $gr3;
			max-width: 68%;
			word-wrap: break-word!important;
			float: left;
		}
		.param_v {
			float: right;
			width: calc(30% - 5px);
			text-align: left;
			padding-left: 5px;
		}
	}
}

#otziv_block {
	.stars {
		color:$gn;
		font-size: 24px;
line-height: 28px;
		margin-bottom: 15px;
		.number {
			font-family: $hf;
font-weight: bold;
color: $bk;
		}
	}
	.rating_text {

	}
}

#review {
	table {
		border-top:1px solid $gr4; 
		td {
			border:0;
			.fa {
				color: $gn;
				font-size: 14px;
				width: 15px;
			}
			.author {
				float: left;
			}
			.stars {
				float: left;
				margin-left: 10px;
			}
			
		}
		.date-review, .author {
				font-family:  $hf;
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
			}
	}
}

.recaptcha_container {
	.col-sm-2 {
		width: auto;
	}
}

.cheap_invoice:first-child {
	margin-bottom: 15px;
}

.storage_widget {
	margin-top: 40px;
	border: 0!important;
	padding: 10px 0 10px 90px;
	font-size: 16px;
	line-height: 24px;
	background: url(#{$image-theme-path}i/reserv.svg) no-repeat left center;
	max-width: 565px;
	.storage_time {
		color: $bk;
	}
	table {
		margin: 25px 0 15px 0;
		width: 100%;

		tr {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			td {
				&:first-child {
					span {
						border: 0 !important;
						border-radius: 50% !important;
						height: 10px;
						width: 10px;
						background: #a5a5a5 !important;
						vertical-align: -1px;
						display: none!important;
					}
				}
				&:nth-child(2) {
					padding-left: 0 !important;
				}
				&:last-child {
					margin-top: 20px;
				}
			}
		}

	}
}
.tabs {
		border: 1px solid $gr;
		margin-top: 50px;
		font-size: 16px;
line-height: 24px;
		.tabs__caption {
			margin: 0 0 25px 0;
			padding: 0;
			width: 100%;
			background: $gr;
			display: block;
			overflow: hidden;
			li {
				list-style: none;
				float: left;
				font-family:$hf;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				padding: 20px 30px;
				text-transform: uppercase;
				cursor: pointer;
			&.active {
				color: $rd2;
				background: $wh;
			}
			}
		}
		.tabs__content {
		  display: none;
		  padding: 0 30px 30px 30px;
		  @include transition(all .2s ease-in-out);
		  opacity: 0;
		  &.active {
		  	opacity: 1;
		  	display: block;
		  }
		  .dop_opis {
		  	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
		  	padding: 25px;
		  	margin-bottom: 25px;
		  	ul {
		  		padding: 0 0 0 20px;
		  		li {
		  			list-style-type: disc;
		  			a {
		  				text-decoration: none;
		  				&:hover {
		  					color: $rd;
		  				}
		  			}
		  		}
		  	}
		  }
		  .desc-header {
		  	font-family:$hf;
font-weight: bold;
font-size: 18px;
line-height: 24px;
			margin: 0 0 15px 0;
		  }
		  #tab-downloads {
		  	ul {
		  		padding: 0;
		  		li a {
		  			padding-left: 30px;
		  			background: url(#{$image-theme-path}i/download.svg) no-repeat left 4px;
		  			display: block;
		  			text-decoration: none;
		  			&:hover {
		  				color: $rd;
		  			}
		  		}
		  	}
		  }
		}
}
#review_btn {
	margin:25px 0;
}

.tabs_product_module {
	margin-top:50px;
	&.home_bestseller, &.home_svar, &.home_stab {
		margin-top: 0;
	}
	& + .bpt {
		border-top: 1px solid $gr;
	}
}

#products {
	&.pag-cont {
		border-bottom: 0;
		margin-bottom: -1px;
	}
}

#kupit_mob {
	position: fixed;
	width: 100%;
	bottom:0;
	left:0;
	background: #fff;
	padding: 20px 24px;
	z-index: 99;
	button {
		font-family: $hf;
		border: 0;
		background: $rd2;
		width: 100%;
		font-weight: bold;
font-size: 24px;
line-height: 28px;
		color: $wh;
		text-align: center;
		text-transform: uppercase;
		padding: 17px 0;
		.price-old {
			font-size: 18px;
			color: $gr;
		}
	}
}