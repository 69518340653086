body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

/* search */
#searchtop {
	border-radius: 3px;
	background: $gr;
	margin: 25px 0 0 0;
	padding: 5px;
	input.form-control {
		background: transparent;
		border-radius: 0;
		border: none;
		color: $dg;
		font-style: normal;
		font-size: 16px;
		@include placeholder($dg);
	}
	.btn-search {
		display: block;
		cursor: pointer;
		background: none;
		@include transition(all 0.2s ease-in-out);
	}
}

.form-control::-moz-placeholder {
	color: #aaa;
}

// module cart
#cart {
	height: 72px;
	&.open .dropdown-toggle {
		box-shadow: none;
	}
	a {
		display: inline-block;
		position: relative;
	}
	.icon-cart {
		background: transparent;

		display: inline-block;
		margin: 3px 0 0 0px;
		position: relative;
		text-align: right;
		float: left;

		i {
			font-size: 30px;
			padding: 8px 0;
		}
	}
	.wrap-cart {
		display: inline-block;
		color: $wh;
		span {
			position: relative;
			top: 13px;
		}
		.cart-total {
			font-weight: bold;
			line-height: 23px;
			text-transform: none;
		}
		.badge {
			font-family: $hf;
			font-size: 18px;
			line-height: 24px;
			margin: 8px 0 0 10px;
			top: auto;
			padding: 0;
			background: none;
			color: $dg;
			min-width: none;
			float: left;
		}
	}
	.price-cart {
		color: $theme-color;
	}
	.cart-inner {
		text-align: right;
	}
	.cart-outer {
		display: none;
		@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
		position: absolute;
		width: 280px;
		height: auto;
		padding: 25px 25px 0 25px;
		right: -1px;
		top: 52px;
		background: $wh;
		.cart-outer-text {
			font-family: $hf;
			font-weight: bold;
			font-size: 18px;
			line-height: 24px;
		}
		.cart-outer-price {
			font-size: 16px;
			line-height: 24px;
			margin: 15px 0;
		}
		.cart-outer-link a {
			font-family: $hf;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 24px;
			background: $rd2;
			border-radius: 2px;
			display: inline-block;
			color: $wh;
			padding: 8px 16px;
			margin-bottom: 15px;
			&:hover {
				background: $rd;
				text-decoration: none;
			}
		}
	}
	&:hover {
		.cart-outer {
			display: block;
		}
	}
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

/* pagination */
.pagination {
	li {
		span, a {
			@include rounded-corners(3px);
			border: 2px $lb solid;
		}
	}
}

.tabs_product_module {
	.title_module {
		background: $gr;
		vertical-align: middle;
		padding: 0 0 0 28px;
		display: flex;
		align-items: center;
		span {
			width: 100%;
			font-family: $hf;
			font-weight: 800;
			font-size: 40px;
			line-height: 45px;
			color: $rd2;
			padding: 0 0 32px 0;
			text-transform: uppercase;
			border-bottom: 8px solid $rd2;
			display: block;
			right: -2px;
		}
	}
}

#home_about {
	background: url(#{$image-theme-path}cat/home_advantages1700.jpg) center center no-repeat;
	background-size: cover;
	background-position: center;
	color: $wh;
	font-family: $hf;
	margin: 0px 0 100px 0;
	.title {
		padding: 48px 0 48px 48px;

		font-style: normal;
		font-weight: 800;
		font-size: 40px;
		line-height: 45px;
		/* or 112% */

		text-transform: uppercase;
	}
	.link {
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		text-transform: uppercase;
		padding: 0px 0 48px 48px;
		a {
			color: $wh;
			&:hover {
				text-decoration: none;
				color: $rd;
			}
		}
	}
	.sub_title_block {
		padding: 48px 18px 48px 18px;
		.sub_title {
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			text-transform: uppercase;
			margin: 15px 0 20px 0;
			position: relative;
			padding: 15px 0 0 0;
			&:before {
				content: '';
				width: 24px;
				height: 2px;
				background: $rd2;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}

.podpiska {
	background: $gr;
	position: relative;
	z-index: 1;
	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		background: $gr;
		width: 200%;
		margin-left: -50%;
		left: 0;
		height: 100%;
	}
}

#subscribe_block {
	padding: 55px 0;

	.title {
		font-family: $hf;
		font-style: normal;
		font-weight: 800;
		font-size: 40px;
		line-height: 45px;
		text-transform: uppercase;
		margin: 0 0 20px 0;
	}
	.text {
		font-size: 16px;
		line-height: 24px;
	}
	#subscribe_type {
		margin-top: 60px;
		.form-group {
			width: calc(100% - 32px);

		}
	}
	#subscr_email {
		background: none;
		border: 0;
		border-bottom: 2px solid $dg;
		font-size: 16px;
		line-height: 24px;
		border-radius: 0;
		padding: 8px;
		width: 100%;
		&::placeholder {
			color: $dg;
		}
	}
	#subscr_submit {
		background: url(#{$image-theme-path}i/ic_arrow.svg) center center no-repeat;
		border: 0;
		border-bottom: 2px solid $dg;
		margin-left: -4px;
		height: 36px;
		border-radius: 0;
	}
	.checkbox_custom .input-control {
		padding-left: 20px
	}
	.checkbox_custom .input-control__indicator {
		top: 5px;
	}
}

#widget_news {
	margin: 0;
	.title {
		font-family: $hf;
		font-weight: 800;
		font-size: 40px;
		line-height: 45px;
		text-transform: uppercase;
		margin: 0 0 40px 0;
	}
	.widget_news_item_date {
		font-family: $hf;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		color: $rd2;
		margin-bottom: 6px;
	}
	.widget_news_item_img {
		img {
			max-width: 100%;
			width: 100%;
		}
	}
	.widget_news_item_title {
		font-family: $hf;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		text-transform: uppercase;
		margin-bottom: 14px;
		a {
			text-decoration: none;
			&:hover {
				color: $rd2;
			}
		}
	}
	.widget_news_item_description {
		margin-top: 10px;
		font-size: 16px;
		line-height: 24px;
	}
	.widget_news_item_readmore {
		a {
			font-family: $hf;
			font-weight: bold;
			font-size: 16px;
			line-height: 24px;
			text-transform: uppercase;
			text-decoration: none;
			&:hover {
				color: $rd2;
			}
		}
	}
	.link {
		margin: 50px 15px;
	}
}

.cat_home {
	width: 100%;
	position: relative;
	.image-left {
		width: 90%;
		position: relative;
		z-index: 0;
		height: 400px;
		background-position: center;
		background-size: cover;
		&.bg-home_svar {
			background: url(#{$image-theme-path}cat/video_diagnostics.jpg);
		}
		&.bg-home_stab {
			background-image: url(#{$image-theme-path}cat/cutting_pipes.jpg);
			height: 500px;
		}
		img {
			position: relative;
			z-index: 0;
		}
		.opisanie {
			position: absolute;
			top: 35%;
			left: 5%;
			z-index: 1;
			.title {
				font-family: $hf;
				font-weight: 800;
				font-size: 64px;
				line-height: 68px;
				text-transform: uppercase;
				color: $wh;
			}
			.link {
				margin: 25px 0 0 0;
				a {
					font-family: $hf;
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;
					text-transform: uppercase;
					color: $wh;
					text-decoration: none;
					&:hover {
						color: $rd;
					}
				}
			}
		}
	}
	.image-right {
		position: absolute;
		right: 0;
		top: 10%;
		width: 39%;
		background: $dg;
		padding: 25px;
		color: $wh;
		&:after {
			content: '';
			position: absolute;
			left: -12px;
			width: 24px;
			top: 15%;
			height: 70%;
			background: $rd2;
		}
		ul {
			padding: 0 0 0 20px;
			li {
				list-style: none;
				a {
					font-family: $hf;
					display: block;
					font-weight: bold;
					font-size: 18px;
					line-height: 24px;
					color: $wh;
					margin: 5px 0 15px 0;
					text-decoration: none;
					&:hover {
						color: $rd;
					}
				}
			}
		}
		.more {
			margin: 20px 0 10px 20px;
			a {
				font-family: $hf;
				display: block;
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				color: $rd2;
				text-transform: uppercase;
				text-decoration: none;
				&:hover {
					color: $wh;
				}
			}
		}
	}
}

.bpt {
	padding: 15px 0;
	margin: 0 -15px;
}

#widget_news .news-block-5 {
	display: none;
}

@media screen and (max-width: 1449px) {
	#widget_news .news-block-4 {
		display: none;
	}
}

@media screen and (max-width: 992px) {
	#widget_news .news-block-3, #widget_news .news-block-2 {
		display: none;
	}
	.tabs_product_module .title_module span {
		font-size: 30px;
		padding: 0 0 20px 0;
		line-height: 36px;
	}
}
@media screen and (min-width: 768px) {
	.tabs_product_module .title_module span {
		word-spacing: 80px;
	}
	.product-info .tabs_product_module .title_module span {
		word-spacing: normal;
	}
}
@media screen and (max-width: 767px) {
	.tabs_product_module .title_module span {
		word-spacing: initial;
	}
}

@media screen and (min-width: 1450px) {
	.col-lg-5ths {
		width: 20%;
	}
	.col-ws-6 {
		width: 16.666666666%;
	}
	.col-ws-3 {
		width: 33.333333333%;
	}
	.container, .container-fluid {
		width: 100%;
		max-width: 1700px;
	}
}


