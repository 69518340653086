/******************************************************
 * $package Pav Opencart Theme Framework for Opencart 1.5.x
 * $version 1.1
 * $author http://www.pavothemes.com
 * $copyright   Copyright (C) Augus 2013 PavoThemes.com <$emai:pavothemes$gmail.com>.All rights reserved.
 * $license     GNU General Public License version 2
 *******************************************************/
html {
	overflow-x: hidden;
}

body {
	&.layout-boxed-lg {
		#page {
			max-width: $container-lg + 20px;
			@include box-shadow(0 0 5px lighten($gray, 50%));
			margin: 0 auto;
			background: $white;
		}
	}
	&[class*="-home"] {
		.header-1 {
			.pav-verticalmenu {
				display: none !important;
				border: 1px solid #000;
			}
			.dropdown {
				&:hover {
					.pav-verticalmenu {
						display: block;
					}
				}
			}

		}
	}
	&[class*="page-"], &[class*="page-"] {
		#content {
			padding: 0;
		}
	}
	&[class*="page-category"] {
		#content {
			padding: 0;
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="-account"] #content {
		h1, h2 {
			font-size: $font-size-base + 5;
		}
	}

	&[class*="page-product"] #content {
		overflow: visible;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
		.owl-carousel {
			margin-bottom: 35px;
		}
	}
}

.container-inside {
	margin-right: 10px;
	min-height: calc(100vh - 548px);
	& > .row {
		margin: 0;
	}
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

.container-fluid {
	max-width: 1200px;
}

/*
* HEADER 
*/

#topbar {
	background: $topbar-outside-bg;
	font-size: $topbar-font-size;
	color: $topbar-color;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;
	.topbar-left {
		position: relative;
	}
	.custom-top {
		&:nth-child(1) {
			margin-right: 20px;
		}
		i {
			padding-right: 5px;
		}
	}
	.container {
		.inside {
			background: $topbar-bg;
			padding: $topbar-container-padding;
		}
	}

}

.header-top {
	position: relative;
	height: 100px;
	.logo-container {
		a {
			text-decoration: none !important;
		}
		img {
			display: block;
			margin: 15px 0 10px 0;
		}
		span {
			font-size: 12px;
			line-height: 16px;
			display: block;
		}
	}
	&, a {
		color: $dg;
	}
	& > .container, & > .container-fluid {
	}
	.btn-group {
		margin-right: 20px;
		button {
			background-color: transparent;
			border: none;
			padding: 0;
		}
		&:hover {

		}
	}
	.topcontact_block {
		float: right;
		position: relative;
	}
	.topcontacts {
		&.worktime {
			font-size: 12px;
			line-height: 16px;
			margin: 22px 0 5px 0;
			text-align: left;
		}
		&.phone {
			font-family: $hf;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			a {
				color: $bk;
			}
		}
	}
	.headercart {
		flex: 1 0 200px;
	}
}

.header-bottom {
	background: $gr;
	height: 72px;

	.main-menu {

	}
}

.shopping-cart-table {
	background: $white;
	vertical-align: top;
	tbody {
		.column-image {
			position: relative;
			padding: 0;
			img {
				border: none;
			}
			.product-label {
				top: 5px;
				left: 5px;
				width: auto;
				height: auto;
				font-size: 14px;
			}
		}
	}
	& > thead:first-child > tr:first-child > td {
		border-top: 1px solid #ddd;
	}
	&.table {
		tr td {
			border: 1px #ddd solid;
		}
	}
}

.buy_products {
	.cart-top-padding {
		padding-top: 25px;
	}
	.product-label {
		top: 5px;
		left: 5px;
		width: auto;
		height: auto;
		font-size: 14px;
	}
	.list_product_cart {
		.row {
			border: 1px solid $gr;
			padding-top: 15px;
			padding-bottom: 15px;
			.row {
				border: 0;
				padding-top: 0;
				padding-bottom: 0;
			}
			.title {
				font-family: $hf;
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
				display: block;
				margin: 0 0 15px 0;
			}
			.price {
				font-size: 16px;
				line-height: 24px;
			}
			.product-quantity {
				position: relative;
				padding-right: 25px;
				display: inline-block;
				background: $gr;
				border-radius: 3px;
				button {
					position: absolute;
					right: -20px;
					top: 0;
					border: 0;
					height: 50%;
					padding: 0px;
					margin: 0;
					background: none;
					&.sub {
						top: auto;
						bottom: 3px;
					}
					&.add {
						top: -3px;
						bottom: auto;
					}
				}
				input {
					border: 0;
					background: none;
					text-align: center;
				}
			}
			.tot {
				font-family: $hf;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
			}
			.btn-delete {
				font-family: $hf;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				background: none;
				padding: 0;
				border: 0;
				margin: 25px 0 0 0;
				text-transform: uppercase;
				color: $gr3;
				&:hover {
					color: $rd;
				}
			}
		}
	}
}

.bottom_cart {
	background: $gr;
	margin: 0px 0 30px 0;
	height: 160px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	.row {
		width: 100%;
	}
	#load_numAll_show {
		text-transform: uppercase;
	}
	tbody {
		display: flex;
		width: 100%;
		flex-direction: column;
		.total-item {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}
	}

	table {
		background: none !important;
		margin: 0;
		td {
			border: 0 !important;
			text-align: left !important;
			background: none !important;
			padding: 5px 0 !important;
			font-size: 16px;
			line-height: 24px;
			strong {
				font-family: $hf;
				font-weight: bold;
				font-size: 18px;
				line-height: 28px;
				text-transform: uppercase;
			}
		}
	}

}

#checkout-form {
	.cart_title2 {
		margin-bottom: 10px;
	}
	.form-control {
		padding-top: 0;
		&#input-comment {
			padding-top: 9px;
		}
	}
	.form-group {
		margin-bottom: 10px;
	}
	.control-label {
		visibility: hidden;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 12px;
		color: $gr3;
		margin: 0;
		&.active {
			visibility: visible;
		}
	}
}

#buy_form_wrapper {
	position: relative;
	margin-bottom: 50px;
	#promo_kod {
		position: absolute;
		bottom: 26px;
		left: 0px;
		.panel-body {
			padding-left: 44px;

		}
		#coupon_form {
			.title {
				font-family: $hf;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				text-transform: uppercase;
				margin: 0 0 15px 0;
			}
			.coupon_button {
				display: inline-block;
				border-bottom: 2px solid $bk;
				input {
					padding: 0;
					background: none;
					border: 0;
					width: 300px;
					@include placeholder($bk);
				}
				#button-coupon {
					background: none;
					padding: 0;
					font-family: $hf;
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;
					color: $bk;
					border: 0;
				}
			}
		}
		.alert {
			position: absolute;
			z-index: 1;
			.close {
				margin-left: 10px;
			}
		}
	}
}

.cart_title2 {
	font-family: $hf;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	text-transform: uppercase;
	margin: 0 0 25px 0;
}

.cart_title3 {
	font-family: $hf;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	text-transform: uppercase;
	margin: 0 0 15px 0;
}

#client-form {
	border: 1px solid $gr;
	margin-bottom: 50px;
	padding: 25px;

	.secury_pay {
		font-size: 16px;
		line-height: 24px;
		border-left: 4px solid $gn;
		padding-left: 20px;
		margin-top: 40px;
		margin-bottom: 30px;
		.title {
			font-family: $hf;
			font-weight: bold;
			font-size: 18px;
			line-height: 24px;
			padding: 10px 0 15px 40px;
			background: url(#{$image-theme-path}i/secure_pay.svg) no-repeat left center;
		}
	}
	.secury_text {
		color: $gr3;
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 10px;
	}

	.form-control {
		@include rounded-corners(0);
		border: none;
		@include placeholder($bk);
		color: $bk;
		padding-left: 0;
		font-size: 16px;
		line-height: 16px;
		border-bottom: 2px solid $bk;
		padding-top: 0;

	}

}

.modal-title {
	font-size: 24px;
	margin-bottom: 25px;
	text-align: center;
	color: $bk;
	margin: 0;
}

.modal-body {
	h3 {
		font-size: 24px;
		margin-top: 0;
		margin-bottom: 25px;
		text-align: center;
		color: $bk;
	}
	.form-control {
		@include rounded-corners(0);
		border: none;
		@include placeholder($bk);
		color: $bk;
		padding-left: 0;
		font-size: 16px;
		line-height: 16px;
		border-bottom: 2px solid $bk;
		padding-top: 0;
	}
	textarea.form-control {
		height: 60px;
	}
}

.reserve_form {
	.col-sm-3 {
		display: none;
	}
	.col-sm-9 {
		width: 100%;
	}
	.reserve_button {
		float: none !important;
		margin: 0 auto 10px auto;
		width: 150px;
		display: block;
	}
}

.quick-view {
	.image-container {
		width: 40%;
	}
	.product-view {
		width: 60%;
	}
	.title-product {
		font-size: 18px;
		line-height: normal;
	}
	.order {
		display: block;
	}
	.order-sm-1 {
		width: 100%;
		order: 2;
	}
	.order-sm-2 {
		width: 100%;
		order: 1;
	}
}

.tp-bullets .counter {
	display: none !important;
}

#shipping_methods {
	border: 1px solid $gr;
	margin-top: 50px;
	margin-bottom: 50px;
	padding: 25px 40px;
	.tabs {
		border: 0;
		margin-top: 0px;
		font-size: 16px;
		line-height: 24px;
		.tabs__caption {
			margin: 0 0 10px 0;
			padding: 0;
			width: 100%;
			background: none;
			display: block;
			overflow: hidden;
			li {
				list-style: none;
				float: left;
				font-family: $f;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				padding: 18px 16px;
				text-transform: none;
				border: 1px solid $gr;
				margin-right: 15px;
				cursor: pointer;
				margin-bottom: 15px;
				&.active {
					color: $rd2;
					background: $gr;
				}
			}
		}
		.tabs__content {
			display: none;
			padding: 0 0px 30px 0px;
			@include transition(all .2s ease-in-out);
			opacity: 0;
			&.active {
				opacity: 1;
				display: block;
			}
		}
	}
}

#payment_methods {
	border: 1px solid $gr;
	margin-top: 50px;
	margin-bottom: 50px;
	padding: 25px 40px;

	.radio_custom {
		list-style: none;
		float: left;
		font-family: $f;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		padding: 18px 16px;
		text-transform: none;
		border: 1px solid $gr;
		margin: 0 15px 15px 0;
		width: calc(25% - 15px);
		cursor: pointer;
		&.active, &:hover {
			color: $rd2;
			background: $gr;
		}
	}
}

#comment_block {
	border: 1px solid $gr;
	margin-top: 50px;
	margin-bottom: 30px;
	padding: 25px 40px;
}

#input-payment-address_1, #input-payment-city_tk, #input-payment-address_2 {
	margin-top: 25px;
	@include rounded-corners(0);
	border: none;
	@include placeholder($bk);
	color: $bk;
	padding-left: 0;
	border-bottom: 2px solid $bk;
}

#block-payment-company {
	margin-top: 25px;
}

#buttons-cart {
	padding: 0px 25px;
	.checkbox_custom .input-control {
		font-size: 14px;
		line-height: 18px;
	}
	.checkbox2 {
		.checkbox {
			padding-left: 0;
		}
	}
}

.tabs_product_module .tab-content {
	border: 0;
}

#modal-agree {
	.modal-body {
		font-size: 14px;
		line-height: 18px;
	}
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/* multi logo */
.logo-store {
	position: relative;
	a {
		vertical-align: middle;
	}
}

#logo-theme {
	a {
		background: url(#{$image-theme-path}information/logo.png) no-repeat;
		@include size(160px, 43px);
		margin: 15px 0 10px 0;
		span {
			display: none;
		}
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

/*
* NAVIGATION
*/
#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

/*
* SLIDESHOW 
*/
#pavo-slideshow {
}

/*
* SHOWCASE 
*/
#pavo-showcase {
	.inner {
		background-color: $showcase-bg;
	}
}

/*
* PROMOTION TOP 
*/
#pav-promotion {
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

/*
* MAIN CONTENT
*/

/*end*/
/* FOOTER */
#footer {
	background: url(#{$image-theme-path}i/bg_footer1440.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	color: $white;
	font-size: 14px;
	padding-bottom: 25px;
	margin-top: 0;
	ul, ol {
		margin: 0;
		li {
			color: $white;
			line-height: 20px;
			a {
				font-size: 14px;
				color: $white;
				line-height: 18px;
				display: inline-block;
				margin: 8px 0;
				@include transition(all 0.4s ease 0s);
				&:hover {
					color: $rd2;
					text-decoration: none;
				}
			}
			&.ft-phone {
				a {
					font-size: 24px;
					line-height: 28px;
					margin: 0;
				}
			}

		}
	}
	.copyright {
		padding-top: 20px;
		a {
			color: $wh;
			text-decoration: none;
			&:hover {
				color: $rd;
			}
		}
	}
	.payment {
		.footer-title {
			padding: 20px 0 5px 0;
		}
		.payment-list {
			list-style: none;
			padding: 0;
			li {
				display: flex;
				align-items: center;

				img {
					height: 61px;
				}
			}
		}
	}
	.direktoru {
		display: inline-block;
		margin: 15px 0 0 0;
		color: $rd2;
		font-family: $hf;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		padding: 8px 16px 10px 16px;
		border: 1px solid $rd2;
		text-transform: uppercase;
		border-radius: 3px;
		&:hover {
			color: $white;
			background: $rd2;
			text-decoration: none;
		}
	}
}

.footer-top {
	margin-bottom: -97px;
}

.footer-center {
	padding: 30px 0;
	color: $white;
	.box {
		.box-heading {
			font-family: $hf;
			font-weight: bold;
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 24px;
		}
	}
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $white;
		}
	}
	.footer-name {
		text-align: center;
		font-family: $hf;
		font-size: 18px;
		span {
			display: inline-block;
			margin-top: 10px;
			margin-bottom: 30px;
		}
	}
	.main-footer {
		display: flex;
		justify-content: space-between;
		margin-left: 100px;
		.box {
			margin-right: 15px;
		}
		.ft-wt {
			display: block;
		}
	}
	.ft-wt {
		padding-top: 8px;
		font-size: 12px;
		line-height: 16px;
	}
	.ft-phone {
		font-family: $hf;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		text-transform: uppercase;
		margin: 5px 0 15px 0;
	}
	.ft-mail {
		margin: 0 0 15px 0;
	}
}

/* POWER BY */
#powered {
	background: $bk;
	a {
		font-size: $font-size-base - 2;
		color: $footer-color;
		font-weight: 500;
		text-transform: uppercase;
		width: 100%;
		&:hover {
			color: $white;
		}
	}
	.copyright {
		color: $white;
		font-size: $font-size-base - 2;
		font-weight: 500;
		text-transform: uppercase;
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.oferta {
			color: $wh;
			font-size: 12px;
		}
	}
}

.information-news-item {
	h1.top0 {
		padding-bottom: 15px;
	}
	.panel-heading {
		padding: 10px 0 0;
	}
	.panel-body {
		padding: 10px 15px 0 0;
	}
	.container-inside > .row {
		display: flex;
	}
	#content {
		order: 1;
		flex: 1 80%;
	}
	.news_column {
		order: 2;
		flex: 0 20%;
		.news_item {
			border-top: 1px solid #ccc;
		}
		h3 {
			margin-top: 10px;
		}
	}
	.allnews {
		font-family: "Akrobat", sans-serif;
		font-size: 22px;
		font-weight: 600;
		padding-bottom: 10px;
		display: block;
	}
}

#news_list {
	display: flex;
	flex-wrap: wrap;
	.news_item {
		flex: 0 20%;
		padding: 0 15px 15px 0;
	}
	.news_date {
		font-family: "Akrobat", sans-serif;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		color: #EE423E;
		margin-bottom: 6px;
	}
	h3 {
		font-family: "Akrobat", sans-serif;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		text-transform: uppercase;
		margin-bottom: 14px;
	}
}