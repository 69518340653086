.owl-carousel {
	display: block !important;
}

.category-info {
	padding: 50px 0 0 0;
	margin-left: -1px;
	background: #fff;

	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}
.cat-item {
	background: $gr;
		display: block;
}
.filter-right {
	& > div {
		display: flex;
		align-items: center;
	}
	.sort {
		margin-left: 15px;
	}
	label {
		line-height: 14px;
	}
}

.refine-search {

	margin-bottom: 60px;
	overflow: hidden;

	ul, ol {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		li {
			padding: 5px;
			line-height: normal;
			position: relative;
			width: calc(100% / 5);

			a {
				display: flex;
				flex-wrap: wrap;
				color: $bk;
				line-height: 24px;			
				padding: 24px 16px;
				background: $gr;
				text-decoration: none;
				height: 100%;
				span {
					display: block;
					width: 70%;
					font-size: 18px;
					line-height: 24px;		
					font-weight: bold;			
					font-family: $hf;
					&.image {
						width:30%;
					}
				}
				&:hover {
					color: $rd;
				}
			}
			@media (max-width: 1449px) {
				width: calc(100% / 4);
			}
			@media (max-width: 1024px) {
				width: calc(100% / 3);
			}
			@media (max-width: 500px) {
				width: calc(100% / 1);
			}
			@media (max-width: 400px) {
				padding: 7px 10px;
				a span {
					font-size: 14px;
				}
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}

}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

/* 
 *  LISTING PRODUCTS PAGE 
 */
.product-filter {
	color: $bk;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	justify-content: space-between;
	padding: 0;
	color: $gr3;
	height: 71px;
	&.filter_top .filter-right {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-content: center;
		.f_button_top {
			height: 40px;
			padding: 0 14px !important;
			text-align: center;
			margin: 0 25px;
		}
	}
	span {
		margin-right: 5px;
	}
	.filter-right {
		width:100%;
		border: 1px solid $gr;
		padding: 20px 0;
		margin-bottom: -1px;
	}
	.filter-right > div {
		padding-left: 5px;
		&.limit {
			margin-right: 15px;
			padding-left: 0;
			padding-right: 5px;
		}
	}

	.display {
		float: left;
	}
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
	}
	.btn-switch {
		display: inline-block;
		margin-right: 5px;
		color: #ccc;
		background: transparent;
		padding: 0;
		line-height: 35px;
		font-weight: 500;
		font-size: 24px;
		@include transition (all 200ms ease 0s);
		i {
			padding: 0 5px;
		}
		&:hover, &.active {
			color: $bk;
			@include box-shadow (none);
		}
	}
	.selectric {
		text-transform: lowercase;
		border: 0;
		.label {
			font-size: 16px;
line-height: 24px;
		padding: 3px 0;
		}
		.button {
			&:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    right: 6px;
    top: 7px;
	}
	}
	}
}
.product-filter.filter_bottom {
	height: 72px;
	max-height: inherit;
}
.clearfix {
	float: none;
	clear: both;
}
.clearfix:before {
	content: '';
	display: table;
	width:100%;
}

.paging {
	max-width: calc(100% - 205px);
	position: relative;
	.pagination {
		overflow: hidden;
		border-radius: 0;
		
		display: block;
		& > li {
			display: block;
			float: left;
			font-family: $hf;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			width:72px;
			line-height: 72px;
			height: 72px; 
			margin: 0;
			border:0;
				border-radius: 0;
			span {
				background: $rd2;
				color: $wh;
				display: block;
				border:0;
				border-radius: 0;
				line-height: 72px;
				font-weight: bold;
                font-size: 24px;
				width:100%;
				height: 100%;		
				&:hover {
					background: $rd2;
				color: $wh;
				}
			}
			a {
				background: $gr;
				color: $bk;
				display: block;
				border:0;
				border-radius: 0;
				line-height: 72px;
				font-weight: bold;
                font-size: 24px;
				width:100%;
				height: 100%;	
				border: 0;
				text-decoration: none;
				&:hover {
					background: $rd2;
				color: $wh;
				}	
			}
		}		
	}
}
.cat_row .paging {
	margin-bottom: 0;
}
.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding: 0 20px;
	}
	.product-block {
		display: flex;
		border: 1px solid #eee;
		margin: 10px 0;
		border-radius: 0;
		.name {
			font-size: 30px;
			line-height: 1.2;
			margin-bottom: 15px;
		}
		.priice {
			line-height: 1.5;
		}
		.description {
			font-size: 16px;
			margin-top: 15px;
		}
		.block-img {
			flex: 0 0 30%;
			overflow: hidden;
			position: relative;
		}
		.bottom {
			display: flex;
			justify-content: space-between;
			& > div {
				flex: 0 0 150px;
			}
			.bigbtn {
				height: 40px;
				line-height: 24px;
				display: inline-block;
			}
			button.bigbtn {
				line-height: 17px;
			}
			.action {
				display: inline-block;
				padding: 4px 0;
				text-align: right;
				order: 2;
				& > div {
					display: inline-block;
				}
			}
			.cart {
				button {
					padding: 0 30px;
				}
				display: inline-block;
			}
		}
	}
}

.product-grid {
	.product-block {
		margin: 0;
		width: calc(100% / 6);
		border: 1px solid $gr;
		@media(max-width: 1449px) {
			margin: 0 0 7px 0;
			width: calc(100% / 4);
		}
		@media(max-width: 767px) {
			.price {
				font-size: 28px;
			}
		}
	}
}

.product-grid[id^="no-scroll"] {
	margin-top: 45px;
	.product-block {
		margin: 0 7px 10px 0;
		width: calc(100% / 4 - 10px);
		&:nth-child(4n) {
			margin-right: 0;
		}
		@media(max-width: 1199px) {
			width: calc(100% / 3 - 7px);
			&:nth-child(4n) {
				margin-right: 7px;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
		@media(max-width: 991px) {
			&:nth-child(3n) {
				margin-right: 7px;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}

.pb30 {
	padding-bottom: 30px;
}

.functions {
	position: absolute;
	z-index: 99;
	bottom: 0;
	left: 20px;
	@include rounded-corners(50%);
	@include size(60px, 60px);
	border: 2px solid $lb;
	font-family: $hf;
	text-align: center;
	background: $wh;
	.product-info & {
		bottom: 20px;
	}
	span {
		display: inline-block;
		position: relative;
		line-height: 1;
		color: $lb;
		&.count {
			font-size: 21px;
			top: 7px;
			width: 100%;
		}
		&.capt {
			font-size: 12px;
			top: -1px;
		}
	}
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			padding: 15px 0;
		}
	}
	.product-block {
		display: inline-block;
		@include transition(box-shadow 0.2s ease-in-out);
		padding: 0;
		overflow: hidden;
		.price {
			height: 20px;
		}
		.block-img {
			position: relative;
		}
		.cart {
			padding: 0;
			.bigbtn {
				width: 120px;
			}
		}
		.bottom {
			display: flex;
			justify-content: space-between;
			@include transition (all 400ms ease 0s);
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 999;
			text-align: left;
			padding: 0;
			.bigbtn {
				height: 40px;
				line-height: 24px;
				display: inline-block;
			}
			button.bigbtn {
				line-height: 17px;
			}
			.action {
				text-align: left;
				& > div {
					display: inline-block;
					vertical-align: top;
				}
			}
		}
		&:hover {
			background-color: $gbg2;
			z-index: 1;
		}
		.image {
			.action {
				position: absolute;
				top: 15px;
				right: 15px;
				width: 40px;
				button, a {
					border: 0;
					background: rgba(255, 255, 255, .5);
					@include transition(all 0.2s ease-in-out);
					&:hover {
						background: rgba(255, 255, 255, 0.2);
						color: $theme-color;
					}
					&:focus {
						background: rgba(255, 255, 255, .5);
						color: $bk;
					}
				}
			}
		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
}

.owl-carousel {
	.product-block {
		width: 100%;
	}
}

/* 
* PRODUCT DEALS
*/
.productdeals {
	.widget-inner {
		.carousel {
			position: static;
		}
	}
}

.product-info {
	margin-top: 0px;
	.quick-view & {
		margin: 20px;
	}
	.tabs_product_module.home_featured {
		border-top:1px solid $gr;
	}
	.box-product-infomation {
		margin-top: 20px;
	}
	.title-product {
		font-size: 40px;
		margin-top: 0;
		font-weight: 500;
		padding: 0;
	}
	.check-box {
		color: $lb;
	}
	.image-additional {
		overflow: hidden;
		padding: 0 30px;
		.owl-carousel {
			margin-bottom: 0 !important;
			.owl-prev, .owl-next {
				top: calc(50% - 23px);
			}
		}
		.item {
			text-align: center;
			margin: 0 2px;
			background-color: $white;
			position: relative;
			a {
				display: inline-block;
				border: 0;
				&.active {
					border: 0;
				}
			}
		}
	}
	.harakteristiki {
		padding: 7px 0 5px 0;
		li {
			list-style: none;
			display: block;
			font-size: 16px;
            line-height: 24px;
            margin: 0 0 8px 0;
            span {
            	font-family: $hf;
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
            }
		}
	}
	.secury_pay {
		font-size: 16px;
		line-height: 24px;
		border-left:4px solid $gn;
		padding-left: 20px;
		.title {
			font-family:$hf;
			font-weight: bold;
			font-size: 18px;
			line-height: 24px;
			padding: 10px 0 15px 40px;
			background: url(#{$image-theme-path}i/secure_pay.svg) no-repeat left center;
		}
	}
	.product_right_info {
		margin-top: 7px;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
		padding: 24px;
		overflow: hidden;
		.cheap {
			font-size: 12px;
			line-height: 16px;
			color: $gr3;
			text-decoration: none;
			&:hover {
				color: $rd;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.vertical {
		&.image-additional {
			.carousel-control {
				display: none;
			}
		}
		&:hover {
			.carousel-control {
				display: block;
			}
		}

		.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		.top {
			top: 0;
			bottom: auto;
		}

		.bottom {
			top: auto;
			bottom: 0;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;

		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: 0;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $white;
		border: 2px $lg solid;
		& + .btn-sm {
			padding: 0 18px;
		}
		.add-action {
			@include size(40px, 39px);
			color: $black;
			display: block;
			position: relative;
			cursor: pointer;
			line-height: 39px;
			text-align: center;
			font-size: 14px;
		}
		.quantity-number {
			input {
				font-family: $hf;
				@include size(40px, 39px);
				text-align: center;
				font-size: 16px;
				@include inline-block;
				@include rounded-corners(0);
				box-shadow: none;
				padding: 0;
				border: none;
				color: $black;
			}
		}
		.minimum {
			line-height: 38px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		a {
			a.btn-sm {
				padding: 0 19px;
			}
		}
		.quantity {
			margin-bottom: 15px;
		}
		.cart {
			padding-bottom: 15px;
			.btn-primary, .bth-default {
				padding: 8px 10px;
			}
			.btn-primary{
				margin-right: 10px;
			}
		}
		.btn-compare {
			color: $bk;
			font-size: 24px;
			padding: 2px 18px;
			height: 43px;
			display: inline-block;
			line-height: 30px;
			margin-left: 15px;
		}
		#button-cart-credit {
			height: 43px;
		}
	}
	.btn-credit {
		padding: 0;
		background:none;
		border:0;
		font-size: 16px;
		line-height: 24px;
		vertical-align: middle;
		&:hover {
			color: $rd;
			svg {
			fill: $rd;
			}
		}
	}
	.credit_block {
		position: relative;
		display: inline-block;
		width: 100%;
		svg {
			fill: $gr3;
			margin-right: 5px;
			float: left;
			margin-top: 4px;
		}
		.button_credit, .credit_block {
			.btn-credit, .tooltip_block {
				display: inline-block;
				float: left;
			}			
			.tooltip_block {
				cursor: pointer;
				margin: 2px 0 0 6px;
			}
		}
	}

	.price {
		&.detail {
			.price-new {
				font-weight: 800;
				font-size: 40px;
				line-height: 45px;
			}
			.price-old {
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: $gr3;
			}
			ul {
				margin-bottom: 15px;
			}
		}
	}
	.tab-content {
		border: none;
		padding: 0;
		margin-bottom: 0px;
		.desc-header-wrapper {
			border-bottom: 1px $bc solid;
			margin-top: 30px;
			margin-bottom: 15px;
			.desc-header {
				padding: 15px;
				color: $lb;
				font-family: $hf;
				text-transform: uppercase;
				font-size: 30px;
				position: relative;
				top: 1px;
				display: inline-block;
			}
		}
		#tab-description {
			padding: 0 15px;
			p {
				font-size: 18px;
			}
		}
		#tab-cert {
			img {
				max-width: 50%;
			}
		}
		.for-send-error-press {
			font-style: italic;
			margin: 15px 0;
		}
	}
	.desc-pane .widget-product {
		margin: 0 15px;
	}
	.avail_colors {
		ul {
			list-style: none;
			padding: 10px 0 0 0;
			li {
				display: inline-block;
				position: relative;
				margin-right: 5px;
				&:before {
					@include size(49px, 49px);
					border: 1px solid $bk;
					@include rounded-corners(50%);
					background: $wh;
					position: absolute;
					z-index: 1;
					top: -2px;
					left: -2px;
				}
				a {
					position: relative;
					display: inline-block;
					@include size(45px, 45px);
					@include rounded-corners(50%);
					z-index: 2;
				}
				&:first-child {
					&:before {
						content: ' ';
					}
				}
			}
		}
	}
	.prod_info {
		font-family: $hf;
font-weight: bold;
font-size: 16px;
line-height: 24px;
text-transform: uppercase;
color: $gr3;
		margin-bottom: 25px;
		.stock {
			color: $gn;
		}
		.stars {
			color: $gn;
		}
		.custom-color {
			cursor: pointer;
			&:hover {
				color: $rd;
			}	
		}
		.btn-compare {
			margin: 0 0 0 25px;
			display: inline-block;
			padding: 0 0 0 20px;
			color: $gr3;
			position: relative;
			text-decoration: none;
			svg {
				position: absolute;
				left: 0;
				top:3px;
				width: 18px;
				height: 18px; 
				display: block;
				fill: $gr3;
			}
			&:hover {
				color: $rd;
				svg {
					fill: $rd;
				}
			}
		}
	}
}

.product-markdown-view {
	.product_right_info  {
		@include box-shadow(none);
	}
	.product-meta {
		.cart {
			padding: 0;

			.btn-primary {
				margin: 0;
				width: 100%;
			}
		}
	}
	.bottom_block {
		padding: 0;

		div {
			margin: 0;
		}
	}
	.credit_block {
		.button_credit {
			.btn-credit, .tooltip_block, svg {
				float: unset;
			}
		}
	}
	.cheaper-item, .cheaper-products, .cheaper-items, .cheaper-item-info, .wrapper-credit-group, #product {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.price, .cart {
		display: flex;
		gap: 8px;
		align-items: flex-end;
	}
	.wrapper-credit-group {
		gap: 8px;
	}
	.cheaper-item {
		padding: 20px;
		border: 1px  solid $bc;

		.price {
			.price-new, .price-old {
				line-height: 1;
			}
		}
		&:hover {
			text-decoration: none;
		}
		&:not(.general) {
			border: 1px  solid $gr3;

			.price {
				.price-new {
					font-size: 28px;
				}
			}
		}
	}
	.cheaper-products {
		margin-top: 20px;
	}
	.cheaper-item, .cheaper-products {
		&__title {
			font-size: 16px;
			font-weight: 600;
		}
		&__reason {
			color: $gr3;
		}
		&__status {
			color: $gr3;
			font-weight: 600;
		}
	}
}

#simplemodal-overlay {
	background-color: #000;
}

#simplemodal-container {
	background: #fff;
	width: 500px;
	border-radius: 5px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	padding: 20px;
	height: 180px;
}

.simplemodal-close {
	float: right;
	cursor: pointer;
}

.reserve_form {
	display: none;
}

.reserve_button {
	cursor: pointer;
	background: #f52525;
	background-image: -webkit-linear-gradient(top, #f52525, #992323);
	background-image: -moz-linear-gradient(top, #f52525, #992323);
	background-image: -ms-linear-gradient(top, #f52525, #992323);
	background-image: -o-linear-gradient(top, #f52525, #992323);
	background-image: linear-gradient(to bottom, #f52525, #992323);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	font-family: Arial;
	color: #ffffff;
	font-size: 14px;
	padding: 5px 10px 5px 10px;
	text-decoration: none;
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}



.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}

.information-opt {
	.pricelist {
		i {
			padding-right: 15px;
			vertical-align: 2px;
			margin-top: 15px;
		}
		&:hover {
			text-decoration: none;
		}
	}
	.optlogo {
		width: 70%;
	}
	.pl_contact {
		display: flex;
		align-items: center;
		margin: 10px 0;
		i {
			flex: 0 0 35px;
			font-size: 20px;
		}
		.pl_contact_val {
			font-size: 16px;
			display: flex;
			flex-wrap: wrap;
			span {
				flex: 100%;
			}
		}
	}
}

.cat-items {
	margin: 0 0 30px 0;
	height:388px;
	width:100%;
	padding: 100px 0 0 50px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	a {
		display: block;
		width: 100%;
		height: 100%;
		text-decoration: none;
		&:hover {
			.title {
			color: $rd;
			border-bottom-color: $rd;
		    }
		    .count {
			color: $rd;
		}
	  }
	}
	.title {
		font-family: $hf;
		font-style: normal;
		font-weight: 800;
		font-size: 40px;
		line-height: 45px;
		text-transform: uppercase;
		color: $wh;
		text-shadow: 0px 0px 4px rgba(29, 30, 31, 0.5);
		padding-bottom: 20px;
		border-bottom: 3px solid $wh;
		margin-bottom: 15px;
	}
	.count {
		font-family: $hf;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;
text-transform: uppercase;
color: $wh;
text-shadow: 0px 0px 4px rgba(29, 30, 31, 0.5);

	}
}

#vigoda {
	margin: 0 0 80px 0;
	overflow: hidden;
	.product-block {
		.price {
	    font-size: 18px;
	    line-height: 24px;
	    font-weight: bold;
	    height: 30px;
	    	.price-new {
	    		 font-size: 18px;
	    line-height: 24px;
	    	}
	    	.price-old {
			float: left;
			font-size: 14px;
			line-height: 24px;
			color: #999999;
			font-weight: normal;
			font-family: $f;
			padding: 0 0px 0px 10px;
			}
			.podarok {
				color: $rd2;
				font-size: 18px;
    line-height: 24px;
			}
		}
		.name {
			margin-bottom: 25px;
		}

	}
	.more_products {
		color: $rd2;
	}
}
