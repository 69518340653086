.button-credit {
	padding: 0 5px !important;
	margin-left: 12px;
}

.panel {
	margin-bottom: 0;
}

.space-padding-0 {
	padding: 0 !important;
}

button {
	&:active, &:focus {
		outline: none !important;
	}
}

#page {
	position: relative;
	min-height: 100vh;
	& > .container {
		min-height: calc(100vh - 448px);
	}
	&.hover {
		&:after {
		content: '';
		width:100%;
		height: 100%;
		position: absolute;
		left:0;
		top:0;
		z-index: 99;
		background: rgba(60,60,60,0.3);
		-webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
		}
    }
    &.pop_top {
		&:after {
		content: '';
		width:100%;
		height: 100%;
		position: absolute;
		left:0;
		top:0;
		z-index: 101;
		background: rgba(60,60,60,0.3);
		-webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
		}
    }
}

#header-layout {
	position: relative;
	z-index: 100;
	background: #fff;
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

.page-themecontrol-product {
	#page {
		min-height: 1px;
	}
	.product-info {
		.title-product {
			font-size: 30px;
		}
		.price.detail .price-new {
			font-size: 35px;
		}
		.quantity {
			margin-bottom: 15px;
		}
	}
}

.zoomWindow {
	background-color: $wh;
}

.pav-container {
	.panel-body {
		padding: 0;
	}
}

#button-cart {

}

#tab-description {
	img {
		height: auto;
	}
}

#tab-downloads {
	ul li {
		list-style: none;
	}
}

.bigbtn {
	border: 2px solid;
	font-family: $hf;
	padding: 10px 25px;
	text-transform: uppercase;
	font-size: 18px;
	text-decoration: none;
	@include transition(0.2s all);
	background-repeat: no-repeat;
	&.white {
		color: $bk;
		border-color: $rd2;
		background-color: $wh;
	}
	&.transparent {
		border-color: $tc;
		color: $wh;
		background-color: transparent;
	}
	&.yellow {
		background-color: $rd2;
		color: $wh;
		border-color: $rd2;
		padding: 5px 15px;
		&:hover, &:active, &:focus {
			border-color: $bk;
			color: $wh;
			background: $bk;
		}
	}
	&.tags {
		border-color: $lb;
		&:hover, &:active, &:focus {
			border-color: $bk;
		}
	}
	&:hover, &:active, &:focus {
		border-color: $rd;
		text-decoration: none;
	}
}

#mainmenutop {
	.nav.navbar {
		height: 60px;
	}
	.panel-v4 .panel-body {
		border: none !important;
	}
}

.modal-dialog {
	.reserve_button, .callback_button {
		@include rounded-corners(3px);
		background: $rd2;
		color: $wh !important;
		font-family: $hf;
		font-size: 16px !important;
		@include transition(0.2s all);
		border:1px solid $rd2;
		padding: 10px 15px;
		text-transform: uppercase;
		&:hover {
			background: $wh;
			color: $rd2 !important;
		}
	}
}

.contact-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: $rd;
	top: 0;
	position: absolute;
	color: $wh;
	i.fa {
		font-size: 30px;
		padding: 9px 10px 12px 10px;
		width: 50px;
		height: 50px;
		text-align: center;
	}
}

.contact-text {
	display: inline-block;
	padding-left: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
	div.contact-text-b-days div {
		display: inline-block;
		&:first-child {
			width: 170px;
		}
	}
}

.cattop {
	background: transparent no-repeat center top;
	background-size: cover;
	height: 24vw;
}

.search_block {
	overflow: visible;
	form {
		margin: 0;
	}
	button {
		border: none;
		&:focus, &:active {
			outline: none !important;
		}
	}
	.dropdown-menu.autosearch {
		margin: 0;
		width: 350px;
		min-width: 0;
		padding: 0;
		right: 0;
		left: auto;
		display: none;
		border-radius: 0;
		top: 100%;
		li {
			@include transition(0.2s all);
			display: inline-block;
			width: 100%;
			padding-bottom: 5px;
			cursor: pointer;
			line-height: 1;
			position: relative;
			border-bottom: 1px #ebebeb solid;
			&:hover {
				background-color: $gbg1;
			}
			&:last-child {
				background-color: $wh;
			}
			&:nth-last-child(-n+2) {
				border: none;
			}
			> div {
				line-height: 1.5;
				display: inline-block;
			}
			a {
				white-space: normal;
				padding: 3px 5px;
			}
			.as-image {
				float: left;
				padding: 5px;
				width: 80px;
				margin-right: 10px;
				img {
					height: auto;
					width: 100%;
				}
			}
			.as-title {
				width: 100%;
				font-size: 16px;
				margin: 0;
				font-family: $hf;
				position: relative;
				top: 6px;
				padding-bottom: 30px;
				a {
					color: $black;
				}
			}
			.as-price {
				width: calc(100% - 95px);
				font-size: 16px;
				font-weight: 600;
				position: absolute;
				bottom: 7px;
				left: 95px;
				.price-old {
					font-size: 14px;
					text-decoration: line-through;
					margin-right: 7px;
					color: #777;
				}
			}
			#showall {
				padding: 6px 25px;
				margin-top: 15px;
				display: inline-block;
			}
		}
		@media (max-width: 1199px) {
			width: 300px;
			li {
				.as-image {
					width: 80px;
				}
				.as-title {
					width: 100%;
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
				}
				.as-price {
					width: calc(100% - 120px);
					left: 95px;
				}
			}
		}
		@media (max-width: 767px) {
			width: 380px;
			right: 0;
			left: auto;
			top: calc(100% + 4px);
		}
		@media (max-width: 421px) {
			top: calc(100% + 3px);
			width: calc(100vw - 30px);
			left: auto;
		}
	}
}

.producttabs {
	margin-top: 30px;
	margin-bottom: 0;
	padding: 0 20px;
	.tab-header {
		text-align: center;
		font-family: $hf;
		font-size: 45px;
		text-transform: uppercase;
	}
}


#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 0 !important;
}

.radio_custom {
	.list_of_points {
		display: block;
		text-align: left;
		font-size: 14px;
		color: $bk;
		font-weight: 400;
		padding-left: 22px;
	}
	a {
		font-size: 18px;
		color: $red;
		text-decoration: underline;
		width: 100%;
		display: inline-block;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 22px;
		position: relative;
		font-size: 16px;
		font-weight: 400;
		color: $bk;
		margin: 1px 0;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 4px;
			left: 0;
			user-select: none;
			@include size(15px, 15px);
			text-align: center;
			background-color: $wh;
			border: 2px solid $rd2;
		}
		&.radio input ~ .input-control__indicator:after {
			border-radius: 50%;
			content: "";
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: scale(0.95);
			transform-origin: center center;
			width: 100%;
			background: $rd2;
			@include opacity(0);
			@include transition(.1s opacity linear);
			border: 3px $white solid;
		}
		&.radio input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
	@media (max-width: 767px) {
		.input-control, .list_of_points {
			font-size: 14px;
		}
	}
}

.checkbox_custom {
	a {
		&.agree b {
			font-weight: 600;
			padding: 0;
		}
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 30px;
		line-height: 16px;
		position: relative;
		font-size: 12px;
		font-weight: 500;
		color: $bk;
		padding-top: 5px;
		margin: 10px 10px 5px 0px;
		text-transform: none;
		text-align: left;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			user-select: none;
			@include size(24px, 24px);
			text-align: center;
			border: 2px $gr2 solid;
			@include rounded-corners(3px);
		}
		&.checkbox input:checked ~ .input-control__indicator {
			border: 2px $rd2 solid;
		}
		&.checkbox input ~ .input-control__indicator:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 5px;
			transform-origin: center center;
			@include opacity(0);
			@include transition(.1s opacity linear);
			@include transform(translateZ(1px) rotate(45deg));
			border-bottom: $rd2 2px solid;
			width: 10px;
			height: 15px;
			border-right: $rd2 2px solid;
		}
		&.checkbox input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
}

.total-item {
	strong {

	}
}

#button-order {
	margin-top: 15px;
}

.category_widget {
	a {
		.type1 {
			height: 500px;
			background: transparent center no-repeat;
			background-size: cover;
			margin-bottom: 30px;
			position: relative;
			h2 {
				margin: 0;
				position: absolute;
				height: 70px;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0, 0, 0, .7);
				font-weight: 600;
				text-align: center;
				text-transform: uppercase;
				color: $white;
				font-size: 20px;
				padding: 23px 0;
				display: inline-block;
			}
		}
	}
}

.product-compare {
	.comparetable {
		overflow-x: auto;
		.img-thumbnail {
			border: none;
		}
		.table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
			min-width: 250px;
			&:first-child {
				min-width: 180px;
			}
		}
	}
	.compare_actions {
		input, a {
			padding: 3px;
			margin: 7px 0;
			width: 100px;
			display: inline-block;
		}
	}
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
	width: 40px;
	height: 40px;
	text-indent: -9999px;
	z-index: 999;
	@include transition(200ms all ease-in-out);
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -8px;
		margin-top: -12px;
		height: 0;
		width: 0;
		border: 8px solid transparent;
		border-bottom-color: $bk;
	}
}

#coupon_form {
	h4 {
		font-size: 16px;
	}
	#button-coupon {
		padding: 0 16px;
	}
}

.maintenance {
	min-height: calc(100vh - 381px);
}

h1 {
	margin: 0 auto;
		font-family: $hf;
		font-weight: 800;
		font-size: 40px;
		line-height: 45px;
		text-transform: uppercase;
		padding: 0px 0 50px 0;
}
.product-info h1 {
		padding-bottom: 24px;
}

.owl-prev, .owl-next {
	position: absolute;
	top: 40px;
	padding: 10px;
	width: 33px;
	height: 50px;
	color: $bk;
	@include transition(0.2s ease all);
	background: transparent;
}

.owl-prev {
	left: -34px;
}

.owl-next {
	right: -31px;
}

.mfilter-box {
	.box-heading {
		display: none;
	}
	.box-content {
		border: none;
		.mfilter-heading {
			background: transparent;
			border: none;
			border-top: solid 1px $gr;
			text-transform: uppercase;
			.mfilter-heading-content {
				padding: 15px 0 15px 15px;
			}
		}
		.mfilter-heading-text {
			width: calc(100% - 27px);
			margin-left: 0;
			> span {
				padding-left: 0;
				font-size: 14px;
				text-transform: uppercase;
			}
		}
		.mfilter-col-input {
			padding-right: 8px;
		}
		.mfilter-opts-container {
			border-top: 1px solid $lg;
			padding: 9px 0 5px 0;
			label {
				font-weight: 400;
				display: inline-block;
				padding-left: 37px;
				position: relative;
			}
			.mfilter-counter {
				border-radius: 0;
				background: #acacac;
				&:after {
					content: none;
				}
			}
			input[type="text"] {
				border-radius: 0;
				height: 45px;
			}
			.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
				background: $white;
			}
			.mfilter-slider-slider, #mfilter-price-slider {
				border: $lg 1px solid;
				background: $white;
				margin: 15px 2px 0 0 !important;
				.ui-slider-range {
					background: $lg !important;
				}
				.ui-slider-handle {
					background: $white !important;
					height: 16px !important;
					border-radius: 50px;
					width: 16px !important;
					box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
				}
			}
		}
	}
	.mfilter-tb-as-td {
		vertical-align: bottom;
		font-size: 12px;
	}
}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border: 1px solid $lg;
	border-radius: 0;
	background: $white;
	position: relative;
	overflow: hidden;
	.label {
		display: block;
		white-space: nowrap;
		overflow: visible;
		width: calc(100% - 35px);
		text-overflow: ellipsis;
		margin: 0 25px 0 10px;
		padding: 8px 0;
		font-size: 14px;
		color: $black;
		height: 30px;
		font-weight: 400;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		line-height: 1;
	}
	.button {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 38px;
		line-height: 38px;
		background-color: $white;
		color: $black;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			@include size(6px, 6px);
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			@include transform(rotate(45deg));
			right: 10px;
			top: 10px;
		}
	}
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric {
	.button {
		color: #a2a2a2;
		&:after {
			border-top-color: #a2a2a2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric .button::after {
		@include transform(rotate(225deg));
		transform-origin: 4px 5px;
	}
	.selectric-items {
		display: block;
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 31px;
	left: 0;
	background: $white;
	border: 1px solid $lg;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 15px;
		line-height: 20px;
		min-height: 20px;
	}
	li {
		display: block;
		padding: 10px;
		color: $black;
		cursor: pointer;
		line-height: 17px;
		font-family: $hf;
		white-space: nowrap;
		&.selected, &.highlighted, &:hover {
			background-color: $gbg2;
		}
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			color: #444;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

#top_right {
	margin: 0px 0 0 0;
	position: relative;
	float: right;
	height: 72px;
	> div {
		padding-top: 20px;
		float: left;
	}
}

.product-compare-header {
	margin: 0 40px 0 0;
	@include transition(0.2s all);
	
	&:hover {

	}
	a {
		@include transition(0.2s all);
		color: $bk;
		display: inline-block;

		text-decoration: none;
	}
	p {
		margin: 0;
		&#compare-total {
			font-family: $hf;
			font-weight: bold;
			font-size: 18px;
line-height: 24px;
		margin: 8px 0 0 10px;
		float: left;
		}
	}
	div {
		display: inline-block;
	}
	img {
		display: block;
		float: left;
	}
	.badge {
		color: $bk;
		position: absolute !important;
		top: 5px !important;
		right: 10px;
		padding: 3px;
		min-width: 17px;
		line-height: 11px;
		z-index: 99;
		background: $theme-color;
	}
}

.widget-products {
	.owl-stage {
		padding: 15px 0;
	}
	.owl-item {
		&:hover {
			z-index: 400;
		}
	}
	.owl-next, .owl-prev {
		top: calc(50% - 40px);
		font-size: 22px;
	}
}

.page-information, .checkout-buy {
	h1 {
		margin-top: 0;
	}
	h2, h3 {
		&, span {
			font-family: $hf !important;
			font-weight: 400;
		}
	}
	p, li {
		font-size: 16px;
	}
}

.pagescarousel {
	margin: 30px 0;
	.item {
		padding: 0 47px
	}
	h3 {
		margin: 0 0 15px 0;
	}
	.owl-next, .owl-prev {
		top: calc(50% - 20px);
	}
	.owl-prev {
		left: 0;
	}
	.owl-stage {
		display: flex;
		align-items: stretch;
	}
	.owl-item {
		position: relative;
	}
	.pc_wrapper {
		display: flex;
		& > div {
			&.pc_image, &.pc_text {
				flex: 0 0 50%;
			}
			&.pc_text {
				padding-right: 15px;
				p {
					text-align: justify;
					padding-bottom: 46px;
				}
				.btn {
					position: absolute;
					bottom: 0;
				}
			}
			&.pc_image {
				padding-left: 15px;
			}
		}
	}
}

.defhome {
	.bannercontainer {
		position: relative;

		.rev_slider {
			li {
				position: relative;
				img {
					position: relative;
					z-index: 0;
				}
				.slider_desc {
					position: absolute;
					left: 80px;
					top:60px;
					width: 40%;
					.title {
						font-family:$hf!important;
						font-style: normal;
						font-weight: 800;
						font-size: 64px;
						line-height: 68px;
						text-transform: uppercase;
						color: $wh;
						margin: 0 0 30px 0;
					}
					.text {
						font-family:$hf!important;
						font-weight: bold;
						font-size: 24px;
						line-height: 28px;
						text-transform: uppercase;
						color: $wh;
						margin: 0 0 40px 0;
					}
					.link {
						a{
							font-family:$hf;
							font-weight: bold;
							font-size: 16px;
							line-height: 24px;
							text-transform: uppercase;
							color: $wh;
							background: $rd2;
							/* main */
							display: inline-block;
							border: 1px solid $rd2;
							border-radius: 2px;
							padding: 8px 16px;
						}
					}
				}
			}
		}
		.tp-bullets {
			bottom: -13% !important;
			left: 10px !important;
			margin-left: 0 !important;

			.bullet {
				display: inline-block;
				background: #BABABA!important;
				width: 12px!important;
				height: 12px!important;
				border-radius: 50%;
				margin-right: 13px;
				transform: scale(0.7);
				-webkit-transition: all 0.2s ease-out;
				-o-transition: all 0.2s ease-out;
				transition: all 0.2s ease-out;
			}

		}
		.tp-bullets.simplebullets.round .bullet:hover,
		.tp-bullets.simplebullets.round .bullet.selected,
		.tp-bullets.simplebullets.navbar .bullet:hover,
		.tp-bullets.simplebullets.navbar .bullet.selected {
			background-color: $rd2!important;
			border: 0;
			transform: scale(1);
			cursor: pointer;
		}
	}
	.slider_2 {
		.tp-bullets {
			margin-left: 0 !important;
			margin-right: 5px;
			left: auto !important;
			bottom: 3% !important;
			right: 16%;
			z-index: 20;
		}
	}
	.all_action {
		text-align: right;
		border-left: 1px solid $gr;
		a {
			display: inline-block;
			background: $rd2;
			padding: 30px 95px;
			font-family:$hf;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			text-transform: uppercase;
			color: $wh;
			&:hover {
				background: $rd;
				text-decoration: none;
			}
		}
	}
}
.banners_home {
	border-left: 1px solid $gr;
	.fullwidthbanner {
		position: relative;

		ul {
			padding: 0;
		}
	}
	.col-lg-4{
		padding-left: 3px
	}
}


.holiday_works {
	position: absolute;
	top: 8px;
	left: -150px;
	display: inline-block;
	width: auto;
	text-align: center;
	margin-top: 10px;
	border: none !important;
	margin-left: auto !important;
	@media (max-width: 1449px) {
		left: 0;
		bottom: -25px;
		top: unset;
	}
	@media (max-width: 991px) {
		bottom: 0;
		top: 5px;
	}
	@media (max-width: 669px) {
		width: 100px;
		top: 0;
		left: -15px;
	}
}
#is_ip .radio_custom .input-control {
	margin-right: 15px;
}
.type-mail-send {
	list-style: none;
	padding: 0;
}
.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
	padding-top: 0;
}
.page-product .tabs_product_module {
		margin-top: 80px;
	}

.cat_home_2 .cat_home .image-right {
    top: 10%;
}

.mfilter-box {
	.mfilter-iscroll .mfilter-options {
		position: relative;
	}
	.mfilter-col-input input[type=checkbox] {
		width: 24px;
		height: 24px;
	}
	label.mfilter-tb-as-td{
		font-size: 16px;
	}
	.mfilter-button-reset {
		width: 100%;
		margin: 15px 0 17px 0;
	}
	.button_update {
		width: 100%;
	}
	.header_filter {
		font-weight: bold;
		padding: 22px 16px;
		border: solid 1px $gr;
		margin: 0;
		border-right: 0;
		border-left: 0;
		text-transform: uppercase;

	}
	.mfilter-content-opts {
		padding: 0 6px;
		.mfilter-opts-container {
			border: none;
			padding: 0;
		}
	}
	.button {
		display: block;
		position: absolute;
		right: 20px;
		top: 15px;
		width: 38px;
		line-height: 38px;
		color: $black;
		text-align: center;
		-webkit-transition: all 200ms ease 0s;
		-o-transition: all 200ms ease 0s;
		transition: all 200ms ease 0s;
		&:after {
			content: '';
			position: absolute;
			width: 10px;
			height: 10px;
			right: 6px;
			top: 7px;
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			@include transform(rotate(45deg));
			-webkit-transition: all 200ms ease 0s;
			-o-transition: all 200ms ease 0s;
			transition: all 200ms ease 0s;
		}
	}
	.mfilter-heading-content P{
		padding-left: 15px;
	}
	.mfilter-heading.mfilter-collapsed {
		.button{
			top: 18px;
			&:after {
				@include transform(rotate(-45deg));
			}
		}
	}
	.box-content .mfilter-heading-text > span {
		font-size: 18px;
		font-weight: bold;
	}
	.mfilter-heading-text > span, .header_filter {
		font-family: "Akrobat", sans-serif;
	}
	.mfilter-tb-as-td, .mfilter-button-bottom {
		border: none;
	}
	.mfilter-tb-as-td {
		padding: 3px 0;
	}
	ul {
		& > .mfilter-price {
			padding-bottom: 20px;
			.mfilter-price-slider {
				padding: 0 14px 5px 14px !important;
				background: none !important;
			    #mfilter-price-slider {
			    	.ui-slider-handle + .ui-slider-handle{
			    	   margin-left: 0 !important;
			    	}
			    	.ui-slider-range +.ui-slider-handle {
			    	  margin-left: -12px !important;
			    	}
			    }
			}
		}
		.mfilter-price-inputs, .mfilter-slider-inputs {
			display: flex;
			justify-content: space-between;
		}
		.price_min_block, .price_max_block {
			display: flex;
			flex-wrap: wrap;
			align-content: center;
			border: solid 1px #BABABA;
			border-radius: 3px;
			width: calc( 50% - 4px);
			min-width: initial;
			max-width: 120px;
			height: 32px;
			padding: 0 8px;
			font-size: 16px;
			padding-left: 5px;
			span {
				font-size: 16px;
			}
		}
		.price_min_block {
			margin-right: 8px;
		}
		.mfilter-opts-container {
			input#mfilter-opts-price-min, input#mfilter-opts-price-max, input[type="text"].mfilter-opts-slider-min, input[type="text"].mfilter-opts-slider-max  {
				border: none;
				padding: 0;
				height: auto;
				width: auto;
				min-width: initial;
				max-width: calc(100% - 25px);
				background: none;
				font-size: 16px;
				margin-left: 5px;
				text-align: left;
			}
		}

		.mfilter-slider-slider, #mfilter-price-slider {
			height: 7px !important;

		}
		.mfilter-slider-slider .ui-slider-handle, #mfilter-price-slider .ui-slider-handle {
			margin-left: 0!important;
		}
	}

	.mfilter-tb-as-td.mfilter-col-count {
		display: none;
	}
	.mfilter-content input[type=checkbox]{
		display: none;
	}
	.mfilter-content label{
		cursor: pointer;
	}
	.mfilter-content input[type=checkbox] + label:before, .product-search label.checkbox-inline span {
	    content: "\2713";
	    color: transparent;
	    display: inline-block;
	    border: 1px solid #999999;
	    background-size: cover;
	    font-size: 26px;
	    line-height: 22px;
	    margin: -2px 5px 0 0;
	    text-align: center;
	    vertical-align: middle;
	    transition: color ease .3s;
	    box-sizing: initial;
	    font-weight: bold;
	    width: 24px;
	    height: 24px;
	    border-radius: 4px;
	    position: absolute;
	    left: 0;
	    top: 4px;
	    box-sizing: border-box;
	}
	.mfilter-content input[type=checkbox] + label:after {
		content: "";
		display: block;
		position: absolute;
		top: 4px;
		left: 7px;
		transform-origin: center center;
		opacity: 0;
		filter: alpha(opacity=0);
		-webkit-transition: 0.1s opacity linear;
		-o-transition: 0.1s opacity linear;
		transition: 0.1s opacity linear;
		-webkit-transform: translateZ(1px) rotate(45deg);
		-moz-transform: translateZ(1px) rotate(45deg);
		-ms-transform: translateZ(1px) rotate(45deg);
		-o-transform: translateZ(1px) rotate(45deg);
		border-bottom: #EE423E 2px solid;
		width: 10px;
		height: 15px;
		border-right: $rd2 2px solid;
	}
	 .mfilter-content input[type=checkbox]:checked + label:before {
	   border: solid 2px $rd2;
	}
	 .mfilter-content input[type=checkbox]:checked + label:after {
	   opacity:1;
	}
	.mfilter-button-bottom {
		padding:15px;
	}
	.mfilter-iscroll .iScrollVerticalScrollbar  {
		z-index: 99!important;
	}
}
.page-category .cat_row {
	display: flex;
	width: 100%;
}

#sidebar-main {
	margin-bottom: 50px;

	.category-description {
		margin-bottom: 50px;
	}
	.cat-pagination {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: solid 1px $gr;
		.limit {
			display: flex;
			flex-wrap: wrap;
			align-content: center;
			padding-right: 20px;
			max-height: 72px;
			.limit_text {
				padding-top: 3px;
			}
		}
	}
}


.page-product-special {
	.container-inside {
		margin-right: 0;
	}
	.h1_container {
		padding: 0 20px;
	}
}
.page-category .cat_row .wrapper_empty_content {
	padding: 20px;
}
#checkout-form .form-control#input-comment {
	border: none;
}
.bottom_block {
	display: none;
	padding: 25px 0;

	div {
		margin-bottom: 10px;
		b {
			font-family: "Akrobat", sans-serif;
			font-size: 18px;
		}
		span {
			font-size: 16px;
		}
		svg {
			margin: 0 10px -3px 0;
		}
	}
}
#checkout-form .total2_blocks {
	.product_block {
		display: flex;
	}
	.title {
		font-size: 16px;
		margin-bottom: 5px;
	}
	.cart_title2 {
		margin: 30px 0 15px 0 !important;
	}
	.count {
		font-size: 16px;
		color: #999;
		padding-left: 16px;
	}
}


/*//////   mobile menu    //////*/
body .mobile_megamenu {
	position: fixed;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: white;
	z-index: 9999999;
	overflow: auto;
	-webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	-o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	&.active {
		left:0;
	}
	#bs-megamenu {
		margin: 0;
		padding: 0;
		.navbar-nav {
			margin: 0;
		}
	}
	.pav-megamenu {
		.dropdown .dropdown-menu {
			position: fixed;
			display: inline-block;
			top: 0 !important;
			left: -100% !important;
			width: 100%;
			height: 100%;
			z-index: 9999999;
			overflow: auto;
			-webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
			-o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
			transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
		}
		.active.dropdown .dropdown-menu {
			left: 0 !important;
		}
		.dropdown > .dropdown-menu > .dropdown-menu-inner {
		    width: 50%;
		    min-height: 600px;
		    background: $gr;
		    padding: 0;
		}
		.active .dropdown .dropdown-menu {
			left: 0 !important;
		}
		.navbar-nav > li > .dropdown-menu {
		    opacity: 1;
		    filter: alpha(opacity=100);
		    visibility: visible;
		    display: block;
		    top: 100%;
		    .dropdown-menu-inner {
		    	width: 100% !important;
		    	min-height: 100% !important;
		    }
		}
		.navbar-nav > li.aligned-left .dropdown-submenu .dropdown-menu {
			position: fixed;
			top: 0 !important;
			left: -100% !important;
			width: 100%;
			height: 100%;
			z-index: 9999999;
			overflow: auto;
			background: white;
			padding-top: 40px !important;

		}
		.navbar-nav > li.aligned-left .dropdown-menu .dropdown-submenu.active .dropdown-menu {
			left: 0 !important;
		}
	}
	.topcontact_block {
		padding: 56px 15px 15px 15px;
		.topcontacts.phone {
			font-size: 16px;
			line-height: 20px;
			font-family: "Akrobat", sans-serif;
			font-style: normal;
			font-weight: bold;
		}
	}
	.buttom_new_menu {
		margin: 0;
		position: relative;
		left: 0;
	}
	.button_revers_menu1 {
		z-index: 99999999;
	}
	.button_revers_menu1, .button_revers_menu2, .button_revers_menu3 {
		width: 50px;
		height: 50px;
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 20px;
			height: 20px;

			path {
				fill:#3C3C3C;
			}
		}
	}
	.button_revers_menu2, .button_revers_menu3 {
		background: url(#{$image-theme-path}i/ic_arrow.svg) no-repeat 0 0;
		background-position: center center;
		right: auto;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: scale(-1, 1);
	}
	.pav-megamenu .navbar-nav > li > a.dropdown-toggle, .pav-megamenu .navbar-nav > li > a:hover,.pav-megamenu .navbar-nav > li:hover > a.dropdown-toggle, .pav-megamenu .navbar-nav > li.active > a.dropdown-toggle, .pav-megamenu .navbar-nav > li:focus > a.dropdown-toggle {
		background: #ffffff;
		color: $bk;
	}
	.pav-megamenu .navbar-nav li {
		padding: 0 15px;
		&:after {
			content: "";
			width: 100%;
			height: 1px;
			display: flex;
			background: $gr;
		}
	}
	.pav-megamenu .navbar-nav li a {
		padding: 10px 0 16px 0;

	}
	.pav-megamenu .navbar-nav > li {
		height: inherit;
		width: 100%;
		display: inline-block;
	}
	.aligned-left.parent.dropdown > a {
		float: left;
		& > .caret {
			display: none;
		}
	}
	
	.aligned-left.parent.dropdown .caret2 {
		display: inline-block;
		float: right;
		border: 0;
		width: 50px;
		height: 50px;
		background: url(#{$image-theme-path}i/ic_arrow_.svg) no-repeat 0 0;
		background-position: center center;
		margin-left: -50px;
	}
	.pavo-widget > .pavo-widget {
		display: inline-block;
		padding-top: 50px;
	}
	.pav-megamenu .dropdown-menu li a {
		padding: 12px 0;
	}
	.navbar-nav.megamenu .aligned-left.parent.dropdown > .dropdown-menu > .dropdown-menu-inner {
		background: white;
		.row {
			margin:0;
			& > .mega-col {
				padding: 0;
			}
		}
	}

	.pav-megamenu .mega-col .col-sm-12.mega-col {
		padding: 0 5px;
	}
	.pav-megamenu .pavo-widget .pavo-widget {
		.caret {
			background-position: center right;
		}
		 .dropdown-toggle span{
			padding-left: 10px;
			padding-right: 40px;
		}
	}
	.pav-megamenu .dropdown-menu li a:hover {
		color:$bk;
		background: white;
	}
}
.tablet_menu {
	.pav-megamenu .navbar-nav > li:hover > .dropdown-menu, .pav-megamenu .navbar-nav > li:focus > .dropdown-menu {
		opacity: 0;
		filter: alpha(opacity=0);
		 visibility: hidden; 
	}
	.pav-megamenu .navbar-nav > li.active > .dropdown-menu {
		opacity: 1;
		filter: alpha(opacity=100);
		 visibility: visible; 
	}
}
.header_row .buttom_new_menu {
	margin: 0 5px 0 0;
	position: relative;
	left: 0;
}
.over_menu {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: #00000052;
	z-index: 99;
	display: none;
	&.active {
		display: block;
	}
}
#buttons-cart {
	a {
		text-decoration: underline;
	}
}
.sub_category.checkbox_custom .input-control{
	margin: 4px 0 0 0;
}
.table.total-item {
	.td_title strong{
		font-size: 24px;
	}
	.td_value{
		text-align: right!important;
	}
}
.form-group-file {
	margin-top: 30px;
	.input_file {
		position: relative;
	}
	.form-file {
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		cursor: pointer;
	}
}
.aligned-left.parent.dropdown > a + .caret {
	display: none;
}

@media (min-width: 768px) {
	.slider_1 .all_action {
		display: none;
	}	
	.slider_2 .all_action {
	    border: none;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.common-home #page > .main-column.container{
		max-width: 100%;
		& > .row {
			margin: 0;
		}
	}
	.tabs_product_module > .tab-content > .row {
		border-top: 1px solid $gr;
	}
	#subscribe_block .form-inline .checkbox {
		input {
			position: absolute;
		}
		a {
			padding-left: 12px;
			display: inline-block;
		}
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.tabs_product_module > .tab-content > .row {
		.inner_block {
			border-left: 1px solid $gr;
			border-right: 0;
		}
		.inner_block:last-child {
			border-right: 1px solid $gr;
		}
	}
	.tabs_product_module .row {
		&.products_1 {
			border-right: 1px solid $gr;
		}
	}
	.products_button_1 {
		padding: 0;
		border-bottom: 0;
		span {
			display: none;
		}
	}
}
@media (min-width: 992px) and (max-width: 1449px) {
	.bottom_block div span {
	    width: 100%;
	    display: inline-block;
	    margin-left: 38px;
	}
	.storage_widget {
		padding: 10px 0 10px 81px;
	}
}

@media(min-width: 1200px) {
	.page-category {
		#sidebar-main {
			width: calc(100% - 285px);

			.col-ws-6 {
				width: 33.333%;
			}
		}
	}
	.page-product {
		.tabs_product_module, .more_products {
			&.over {
				margin-right: 0;
				margin-left: 0;
			}
		}
	}
}
@media(min-width: 1450px) {
	.page-category #sidebar-main .col-ws-6 {
		width: 25%;
	}
	.thumbnail > img, .thumbnail a > img {
		max-height: 272px;
	}
	.total_block {
		padding-right: 162px;
	}
}
@media(min-width: 1665px) {
	.page-category #sidebar-main .col-ws-6 {
		width: 20%;
	}
}

@media(min-width: 992px) {
	.page-category {
		.cat_row{
			margin-left: 15px;
			border-left: solid 1px $gr;
		}
		#column-left {
			display: inline-block;
			border-right: none;
			border-top: 0;
			width: 100%;
		}
		#sidebar-left {
			padding-left: 0;
			padding-right: 0;
			max-width: 285px;
		}
		#sidebar-main {

			padding-left: 0;
		}
	}
}

@media(max-width: 1449px) and (min-width: 1200px){
	.product-info {
		.pr_center_block {
			width: 38.6%;
		}
		.pr_right_block {
			width: 28%;
		}
	}
}

@media(max-width: 1199px) {
	.page-product-special .h1_container {
		padding: 0 15px;
	}
	#page.hover:after {
		display: none;
	}
	#cart .cart-outer {
		box-shadow: 2px 8px 14px rgba(0,0,0,0.3);
	}
	.nav.navbar-nav.megamenu > .dropdown > .dropdown-menu {
		box-shadow: 2px 8px 14px rgba(0,0,0,0.3);

	}

	.defhome .all_action a{
		width: 100%;
		text-align: center;
	}
}

@media(max-width: 991px) and (min-width: 768px){
	.banners_home {
		.col-lg-8.col-md-8.col-sm-8.col-xs-12 {
			padding: 0 10px 0 15px;
		}
		.col-lg-4.col-md-4.col-sm-4.col-xs-12 {
			padding: 0 15px 0 0;
		}
	}
	.header_row {
		#searchtop {
			position: absolute;
			right: 0;
			top: 5px;
			left: auto;
			width: 100%;
			background: none;
			margin-top: 0;
			.btn-search {
				position: absolute;
				top: 20px;
				right: 150px;
			}
			input.form-control {
				top: 168px;

			}
		}
		.header-serarch-container {
			min-height: 0;
		}
		.cart_compare_top {
			min-height: 0;
			position: inherit;

			#top_right {
				position: absolute;
				right: 0;
				top: 20px;
				height: auto;
				.product-compare-header  {
					margin: 0 20px 0 0;
				}
				.product-compare-header, .headercart  {
					padding-top: 13px;
				}
			}
		}
	}
	#searchtop input.form-control {
		top: 125px;
	}
	#header-layout .header-top {
		.topcontact_block {
			padding: 15px 0 0 0;
			position: relative;
			display: inline-block;
			text-align: left;
		}
		.topcontacts.worktime, .topcontacts.phone, .holiday_works {
			text-align: left;
		}
		.holiday_works{
			position: relative;
			margin-top: 0;
			text-align: left;
		}
		.topcontacts.phone {
			font-size: 24px;
		}
		.logo_sm {
			padding-left: 0;
			.logo_mob {
				padding: 15px 0 10px 0;
				text-align: left;
				line-height: 1.2;
			}
		}
	}
	.main-menu .pav-megamenu .navbar-nav > li > a {
	    padding: 24px 14px;
	}
	.nav.navbar-nav.megamenu > .dropdown > .dropdown-menu {
		max-width: calc(100% - 10px);
		.dropdown-menu {
		    max-width: 369px;
		}
	}
}


@media(max-width: 991px) {
	.sidebar-main2 #content {
	    width: 100%;
	}
	.tree-menu .accordion-group {
		position: relative;
		& > a {
			width: 100%;
			height: 100%;
			max-width: 100%;
			display: inline-block;
		}
		& > .accordion-heading > span {
			width: 30px;
			height: 30px;
			position: absolute;
			top: 15px;
			right: 9px;
			text-align: center;
		}
	}
	.page-category { 
		#sidebar-main {
			width: 100%;
		}
	}
	.product-info .image-additional {
		overflow: visible;
	}
	.page-product #image-additional.image-additional div.owl-carousel .owl-nav .owl-prev, .page-product #image-additional.image-additional div.owl-carousel .owl-nav .owl-next {
		bottom: -37px;
	}
	.page-product #image-additional.image-additional div.owl-carousel .owl-nav .owl-next {
	    right: -45px;
	}
	.tabs .tabs__content #otziv_block.dop_opis {
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	}
	.product-info {
		.credit_block {
			.btn-credit.btn-rassrocka {
				text-align: left;
			}
		}
	}
	#buy_form_wrapper #promo_kod #coupon_form .coupon_button input {
		width: 245px;

	}

}


@media(max-width: 767px) {
	#checkout-form .total2_blocks .count {
		float: right;
	}
	.slider_2_mob .bannercontainer.banner-fullscreen {
		padding: 8px 0 0 0;
	}
	.defhome .sl1_mobile {
		.all_action {
			display: none;
		}
		.tp-bullets {
		    bottom: -25px !important;
		    bottom: 10px !important;
		    left: 0 !important;
		    width: 100%;
		    text-align: center;
		    margin: 0 !important;
		    display: flex;
		    justify-content: center;
		}
	}
	.header_row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&:before, &:after {
			display:none;
		}
		div {
			width: auto;
		}
		.logo_mob {
			padding-top: 0;
		}
		#searchtop {
			padding: 0;
		}
		#top_right {
			min-width: 102px;
			& > div {
				padding-top: 5px;

			}
		}
		.product-compare-header {
			padding: 0;

		}
	}
	.navbar-collapse.collapse {
	    display: block !important;
	    visibility: visible !important;
	    height: auto !important;
	    padding-bottom: 0;
	    overflow: visible !important;
	}
	.header_row .relative {
		margin-left: auto;
		padding: 0;
	}
	.defhome .slider_2_mob .all_action{
		display: inline-block;
		width: 100%;
		margin-bottom: 50px;
		a {
			width: 100%;
			text-align: center;
		}
	}
	#Cart .bottom_cart, #buy_form_wrapper #promo_kod {
		margin: 0 -15px;
		box-sizing: initial;
		padding: 20px 15px 0px 15px;
		height: auto;
		table {
			max-width: inherit;
		}
		.row {
		    width: 100%;
		    margin: 0;
		}
		.coupon_button {
			width: 100%;
			.form-group #button-coupon {
				float: right;
				font-size: 16px;

			}
		}
		#coupon_form {
			padding: 0px 10px 40px 10px;
			.title {
				font-size: 16px;
			}
			 #button-coupon {
				font-size: 16px;
			}
		}

	}
	#buy_form_wrapper #promo_kod .col-xs-12 {
		margin: 0 -15px; 
	}
	#buy_form_wrapper #promo_kod #coupon_form .coupon_button #button-coupon {
		float: right;
	}
	.header_row .img-responsive {
		max-width: 110px;
	}
	.table.total-item {
		.td_title {
			font-size: 16px;
		}
	}
	#checkout-form .total2_blocks .product_block {
		justify-content: space-between;
		margin-top: 15px;
		.title {
			margin-bottom:0;
		}
		.count {
			padding-left: 0;
			width: 100px;
			text-align: right;
			align-self: flex-end;
		}
	}
	.vm--modal {
	    width: 96% !important;
	    max-width: 96%;
	    left: 2% !important;
	}
	.bottom_cart > .row > .col-xs-12 {
		padding: 0;
		.table.total-item .td_title strong {
			font-size: 16px;

		}
	}
	#client-form {
		padding: 0;
		margin: 0 -30px 15px -30px;
	}
	.cart_modal.vm--container {
		.close {
			position: absolute;
			right: 10px;
			top: 10px;
		}
		.modal_body > p > br {
			display: none;
		}
	}
}

@media(max-width: 424px) {
	#products {
		padding: 0;
	}
}
@media(max-width: 355px) {
	.bottom_block div span {
		width: 100%;
		display: inline-block;
		margin-left: 38px;
	}
}